body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin-bottom: 26px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  margin-bottom: 26px; // TODO: use bootstrap navbar height variable here
}

.row {
  margin-bottom: 24px;
}

dl.row {
  margin-bottom: 0;
}

form {
  margin-left: 24px;
}

.card-body form, .modal-body form {
  margin-left: 0;

  .row:last-child {
    margin-bottom: 0;

    .alert {
      margin-bottom: 0;
    }
  }
}

.card-body > :last-child > table {
  margin-bottom: 0;
}

.table:not(.bordered) th {
  border-top: 0;
}

.table td.actions-column, th.actions-column {
  width: 64px;
}

.form-label {
  font-weight: bold;
}

.card-header {
  font-size: 150%;
  font-weight: bold;
}

.card-title {
  font-weight: bold;
}

.form-group#have-request-form .form-check-label {
  font-weight: bold;
}

.react-datepicker__input-container .form-control[readonly] {
  background-color: #fff;
}

.react-datepicker__time-container, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 257px;
}

.react-datepicker-wrapper {
  width: 100%;
}

#csv-export-card {
  max-width: 500px;
}
